import { Link } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';

import CaseOne from '../assets/images/case_1.png';
import CaseTwo from '../assets/images/case_2.png';
import CaseThree from '../assets/images/case_3.png';
import Hero from '../components/Hero';
import Button from '../components/PageComponents/Button';
import Stepped from '../components/PageComponents/Stepped';
import { Subgrid, ThreeColumnGrid, TwoColumnGrid } from '../styles/Grids';
import PageStyles from '../styles/PageStyles';

const CaseStyles = styled.div`
  display: grid;
  grid-template-rows: minmax(28rem, 32rem) auto;
  > div:nth-child(2) {
    background-color: var(--yellow);
    padding: calc((var(--margin)) * 1.5) var(--margin);
  }
  h3 {
    color: var(--yellow);
    font-size: clamp(4rem, 6vw, 6rem);
    font-weight: 700;
    line-height: 1;
    margin-bottom: 1rem;
  }
  b {
    color: var(--white);
    font-size: clamp(3rem, 4vw, 4rem);
    font-weight: 500;
    line-height: 1;
  }
  .itemOne,
  .itemTwo,
  .itemThree {
    display: flex;
    flex-direction: column;
    padding: clamp(1vw, 2vw, var(--margin));
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    b {
      text-shadow: 0px 0px 3px #5c6465;
    }
  }
  .itemOne {
    background-image: url(${CaseOne});
  }
  .itemTwo {
    background-image: url(${CaseTwo});
    justify-content: center;
    align-items: center;
  }
  .itemThree {
    background-image: url(${CaseThree});
    justify-content: flex-start;
    align-items: flex-end;
  }
  .bold {
    margin-bottom: 0;
  }
`;

export default function ElyKehittamispalvelutPage() {
  return (
    <PageStyles>
      <Helmet
        htmlAttributes={{
          lang: 'fi',
        }}
      >
        <title>
          ELY-kehittämispalvelut yritystuki digimarkkinointiin - Digis
        </title>
        <meta
          name="description"
          content="Yritysten kehittämispalvelut on ELY-keskuksen yritystuki markkinoinnin digitalisaatioon. Ota yhteyttä ELY-vastuuasiantuntijaamme. Tilaa maksuton ELY-opas nyt.
"
        />
      </Helmet>
      <Hero>
        <span>ELY-</span>KEHITTÄMIS&shy;PALVELUT
        <br /> YRITYS&shy;TUKI
        <br /> DIGI&shy;MARKKINOINTIIN.
      </Hero>
      <TwoColumnGrid>
        <Subgrid>
          <span className="bar" />
          <Stepped />
        </Subgrid>
        <div>
          <h2>
            <span className="bold"> ELY</span>
            <span className="cursive">-keskuksen</span> YRITYSTEN
            <br />
            KEHITTÄMISPALVELUT.
          </h2>
          <p>
            ELY-keskuksen yritysten kehittämispalvelut on tammikuussa 2021
            käynnistynyt kustannustuettu markkinoinnin ja myynnin
            asiantuntijapalvelu pk-yrityksille. Tuen avulla yritys voi hyödyntää
            markkinoinnin asiantuntijoiden (ELY-palveluntuottajien) osaamista ja
            palveluja kustannustehokkaasti yrityksen nykytilaan, toimialaan ja
            tarpeisiin suunniteltuina. Tuen hakuprosessiin on saatavana opas,
            jossa annetaan lisätietoja tuen myöntämisperusteista ja prosessista.
            Oppaan tilaukseen pääset
            <span className="bold">
              <Link className="link" to="/ota-yhteytta">
                {' '}
                tästä
              </Link>
            </span>
            . Kehittämispalveluiden piiriin hyväksytty yritys maksaa 25 %
            Konsultointi-palvelun kokonaiskustannuksista. ELY-keskuksen tukiosa
            kattaa loput 75 %.
          </p>
          <p>
            Hakuprosessi on kolmivaiheinen. Osa I: Analyysi-palvelu. Osa II:
            Konsultointi-palvelu sekä lisäpalvelu Osa III Koulutus-palvelut.
            Analyysi-palvelu kartoittaa yleisesti liiketoiminnan
            kehittämistarpeet. Kun kehittämistarpeet on selvitetty
            Analyysi-palvelussa, voi yritys hakea Konsultointi-palvelua, joka
            keskittyy markkinoinnin ja asiakkuuksien hankinnan kehittämiseen.
            Lähtökohtana on, että yrityksen nykytilanne arvioidaan ensin
            Analyysi-palvelussa. Yrityksen on mahdollista siirtyä myös suoraan
            Konsultointi-palveluun, markkinoinnin ja asiakkuuksien
            kehittämiseen.{' '}
            <span className="bold">
              <Link className="link" to="/ota-yhteytta">
                Lue lisää ELY-oppaasta.
              </Link>
            </span>
          </p>
          <h2>
            <span className="bold">DIGIS</span> on
            <br /> <span className="bold">ELY</span>-KEHITTÄMISPALVELUIDEN{' '}
            <br /> hyväksytty palveluntuottaja.
          </h2>

          <p>
            Vastaamme ELY-keskuksen II-vaiheen Konsultoinnin palvelusta
            osa-alueena markkinoinnin ja asiakkuuksien kehittäminen.
            Konsultointi-palvelussa perehdymme markkinoinnin, myynnin ja
            asiakashankinnan ongelmiin, uhkiin ja mahdollisuuksiin.{' '}
            <span className="bold">
              <Link className="link" to="/ota-yhteytta">
                Lue lisää oppaasta.
              </Link>
            </span>
          </p>
          <p>
            Tavoitteena on luoda uutta liiketoimintaa, kasvua ja kannattavuutta
            digitalisaation keinoin. Verkostomme ansiosta meillä on yhteydet
            myös I-vaiheen Analyysi-palvelun tuottajiin. Tavoitteena on jouheva
            palvelukokonaisuus, jossa koko paletti toimii ja tähtää
            saumattomasti yrityksen arjen tukemiseen.{' '}
          </p>
          <p>
            Konsultointi-palvelu eroaa Analyysi-palvelusta konkreettisuudellaan.
            Konsultointi-palvelun (2-4 pv) tehtävä on luoda markkinoinnin
            tavoitteet ja viedä ne digitalisaation ja verkkoliiketoiminnan
            keinoin yrityksen arkeen, asiakashankintaan, kasvua tukevaan
            markkinointiin ja myyntiin. Lisätietoja Markkinoinnin ja
            asiakkuuksien Konsultointi-palvelusta saat{' '}
            <a
              className="bold link"
              href="https://www.ely-keskus.fi/web/yritystenkehittamispalvelut/markkinointi-ja-asiakkuudet"
              target="_blank"
              rel="noreferrer"
            >
              ELY-keskuksesta.
            </a>{' '}
          </p>
          <h2>
            <span className="cursive">Miksi juuri </span>
            <span className="bold">DIGIS ?</span>
          </h2>
          <p className="indent">
            Kokemuksen ja satojen casien kautta hankittu kattava
            toimialaosaaminen, liiketoiminnan ydinalueiden sekä toimialan
            olennaistan kilpailutekijöiden tunnistaminen.
          </p>
          <p className="indent">
            Markkinoinnin ja myynnin käytännönläheisyys ja selkokielisyys,
            digimarkkinointia ei jätetä teorian varaan, toimenpiteet
            jalkautetaan ja vakiinnutetaan yrityksen omaan arkeen.
          </p>
          <p className="indent">
            Vahva, sitoutunut ja yrityksen tarpeiden mukaan joustava verkosto
            digitaalisen markkinoinnin, myynnin ja verkkoliiketoiminnan
            huippuammattilaisia.
          </p>
          <p className="indent">
            Tehokas, yrityksen avainhenkilöiden aikaa säästävä
            suunnitteluprosessi. Vuosikymmenien oma yrittäjäkokemus,
            valtakunnallinen, yrityksen sijainnista riippumaton palvelumalli.
          </p>
          <h2>
            <span className="cursive">Tilaa</span> maksuton
            <span className="bold"> ELY</span>
            <span className="cursive">-opas</span>
          </h2>

          <p>
            Oppaan avulla saa selkeän kuvan hakuprosessista ja -ehdoista. Tilaa
            opas sähköpostiisi alla olevasta linkistä.
          </p>
          <Button>
            <Link to="/ota-yhteytta">Opastilaus</Link>
          </Button>
        </div>
      </TwoColumnGrid>
      <ThreeColumnGrid>
        <CaseStyles>
          <div className="itemOne">
            <h3>CASE 1</h3>
            <b>B2B-toimija</b>
          </div>
          <div>
            <p className="bold">Kehittämiskohteet:</p>
            <p>
              Teollisuuden alihankkijan keskeiset ongelmat ovat myynnissä ja
              asiakkuuksien kehittämisessä. Liidien onnistumisprosentti on
              poikkeuksellisen alhainen. Myyntiprosessit ensimmäisestä
              asiakaskontaktista tilaukseen ovat pitkiä ja vaativia.
              Markkinointi ei ole tavoitteellista. Markkinoinnin ja myynnin
              johtaminen ei ole systemaattista
            </p>
            <p className="bold">Toimenpidesuositukset:</p>
            <p>
              Markkinointi ja myynti yhdistetään. Markkinoinnille määritetään
              myyntitavoitteisiin perustuva budjetti. Kylmäsoitot korvataan
              ostotunnelilla, jossa markkinointi tuottaa myynnille tarvittavan
              asiakaspotentiaalin. Kun potentiaalinen asiakas on liidiputkessa,
              myynnin ja viestinnän tehtävä on auttaa asiakasta eteenpäin
              tilaukseen. Lisäksi perustetaan asiakkuudenhallintajärjestelmä -
              CRM, jossa keskitytään asiakaskannan laajentamiseen ja
              asiakaskohtaisen tuloksen parantamiseen. Verkkosivut uudistetaan
              ja hakukoneoptimoidaan. Keskeisessä roolissa on asiakkaan
              ongelmanratkaisuun keskittynyt sisältö ja siihen yhdistetty
              analytiikka.
            </p>
          </div>
        </CaseStyles>
        <CaseStyles>
          <div className="itemTwo">
            <h3>CASE 2</h3>
            <b>Kivijalka</b>
          </div>
          <div>
            <p className="bold">Kehittämiskohteet:</p>
            <p>
              Yksittäinen kivijalan toimipiste jää helposti ainoaksi
              markkinointi- ja myyntikanavaksi. Kilpailukeinot jäävät vähiin ja
              viestinnän määrä on rajallinen. Tunnettuus laskee. Asiakaslupaus
              ja tuotteen arvo jäävät asiakkaalle epäselviksi. Kilpailijat
              tarjoavat saman, jolloin hinta on ainoa ostoperuste. Asiakkaiden
              määrä määrittää markkinoinnin resurssit.
            </p>
            <p className="bold">Toimenpidesuositukset:</p>
            <p>
              Ensimmäinen tehtävä on erottua kilpailijoista omilla tuotteilla
              ja/tai osaamisella. Tuoteportfolio on avainasemassa. Uusilla
              tuotteilla ja uudistuneella hinnoittelulla parannetaan
              kannattavuutta. Myymälän rinnalle nostetaan vahvasti digitaaliset
              markkinointikanavat, joissa asiakkaat ja heidän arvonsa, toiveensa
              ja tarpeensa huomioidaan laajemmin ja monipuolisemmin kuin
              myymälässä. Mielikuvat ruokkivat tarpeita. Sosiaalisen median
              avulla markkinointi laajennetaan samalla alueella uusiin
              kohderyhmiin. Kun verkkosivut on avattu, pystytetään analytiikka.
              Analytiikan seurannalla keskitytään kaikkein tuottavimpiin
              digitaalisiin kanaviin.
            </p>
          </div>
        </CaseStyles>
        <CaseStyles>
          <div className="itemThree">
            <h3>CASE 3</h3>
            <b>Verkkokauppa</b>
          </div>
          <div>
            <p className="bold">Kehittämiskohteet:</p>
            <p>
              Toimintaa aloittelevan verkkokaupan huono tunnettuus, heikko
              hakukonelöydettävyys, puutteellinen asiakasrekisterin (CRM)
              hyödyntäminen ja asiakasymmärrys sekä palautusten ja
              keskeneräisten ostoskorien suuri määrä.
            </p>
            <p className="bold">Toimenpidesuositukset:</p>
            <p>
              Verkkokaupan kohderyhmä-, markkina- ja kilpailija-analyysin
              tuloksena kavennetaan kohderyhmää ja muokataan valikoimaa kapeaa
              niche-kohderyhmää paremmin palvelevaksi. Niche-kohderyhmässä
              tunnettuus paranee sisäisellä “word of mouthilla”. Selkeitten
              kilpailijoiden puuttuessa vältytään hinnanalennuksilta.
              Asiakkuudenhallintaan luodaan asiakasrekisteri (CRM), jossa
              asiakkaat segmentoidaan verkon ostokäyttäytymisen perusteella.
              Kaupan sisällön (tekstisisältö ja kuvamateriaali) optimoinnin
              jälkeen luodaan analytiikka ja sen pohjalta markkinoinnin
              painopisteet digitaalisissa kanavissa. Ostoskori muokataan
              yksisivuiseksi.
            </p>
          </div>
        </CaseStyles>
      </ThreeColumnGrid>
    </PageStyles>
  );
}
