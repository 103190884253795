import React from 'react';
import styled from 'styled-components';

import arrow from '../../assets/svg/arrow.svg';
import circleBottom from '../../assets/svg/circle_bottom.svg';
import circleTop from '../../assets/svg/circle_top.svg';

const SteppedStyles = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(9, auto);
  .circle {
    position: relative;
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    grid-template-rows: repeat(9, auto);

    span,
    img {
    }
    span {
      position: absolute;
      font-family: 'Norican';
      font-size: clamp(2rem, 4vw, 4rem);
      z-index: 1;
      place-self: center;
    }
    img {
      place-self: center;
      grid-column: 1 / span 9;
      grid-row: 1 / span 9;
    }
  }
  .circle-1 {
    grid-column: 4 / 7;
    grid-row: 2 / 5;
  }
  .circle-2 {
    grid-column: 7 / 10;
    grid-row: 5 / 8;
  }
  .circle-3 {
    grid-column: 4 / 7;
    grid-row: 9 / 12;
  }
  .circle-4 {
    grid-column: 7 / 10;
    grid-row: 12 / 15;
  }
  .circle-5 {
    grid-column: 4 / 7;
    grid-row: 16 / 19;
  }
  .circle-6 {
    grid-column: 7 / 10;
    grid-row: 19 / 22;
  }
  .arrow-left {
    width: clamp(4rem, 5vw, 7rem);
    place-self: center;
  }
  .arrow-right {
    width: clamp(4rem, 5vw, 7rem);
    place-self: center;
    transform: rotate(270deg);
  }
  .arrow-1 {
    grid-column: 7 / 9;
    grid-row: 3 / 5;
  }
  .arrow-2 {
    grid-column: 7 / 9;
    grid-row: 8 / 10;
  }
  .arrow-3 {
    grid-column: 7 / 9;
    grid-row: 10 / 12;
  }
  .arrow-4 {
    grid-column: 7 / 9;
    grid-row: 15 / 17;
  }
  .arrow-5 {
    grid-column: 7 / 9;
    grid-row: 17 / 19;
  }
  .blurb-text {
    z-index: 1;
    place-self: center;
  }
  .blurb-text-1 {
    grid-column: 9 / 12;
    grid-row: 1 / 3;
  }
  .blurb-text-2 {
    grid-column: 1 / 6;
    grid-row: 5 / 9;
  }
  .blurb-text-3 {
    grid-column: 9 / 12;
    grid-row: 8 / 11;
  }
  .blurb-text-4 {
    grid-column: 1 / 6;
    grid-row: 12 / 15;
  }
  .blurb-text-5 {
    grid-column: 9 / 12;
    grid-row: 15 / 18;
  }
  .blurb-text-6 {
    grid-column: 1 / 6;
    grid-row: 19 / 22;
  }

  p {
    text-align: left;
    margin-bottom: 0;
  }
  .right {
    text-align: right;
  }
`;

export default function Stepped() {
  return (
    <div className="stepped-wrapper">
      <SteppedStyles>
        <div className="circle circle-1">
          <span>1</span>
          <img src={circleTop} alt="icon-circle" />
        </div>
        <div className="circle circle-2">
          <span>2</span>
          <img src={circleTop} alt="icon-circle" />
        </div>
        <div className="circle circle-3">
          <span>3</span>
          <img src={circleBottom} alt="icon-circle" />
        </div>
        <div className="circle circle-4">
          <span>4</span>
          <img src={circleTop} alt="icon-circle" />
        </div>
        <div className="circle circle-5">
          <span>5</span>
          <img src={circleBottom} alt="icon-circle" />
        </div>
        <div className="circle circle-6">
          <span>6</span>
          <img src={circleTop} alt="icon-circle" />
        </div>
        <img src={arrow} className="arrow-right arrow-1" alt="icon-arrow" />
        <img src={arrow} className="arrow-left arrow-2" alt="icon-arrow" />
        <img src={arrow} className="arrow-right arrow-3" alt="icon-arrow" />
        <img src={arrow} className="arrow-left arrow-4" alt="icon-arrow" />
        <img src={arrow} className="arrow-right arrow-5" alt="icon-arrow" />
        <p className="blurb-text blurb-text-1 cursive right">
          Analyysi-palvelu: Liiketoiminnan ongelmakohtien tunnistaminen
        </p>
        <p className="blurb-text blurb-text-2 cursive">
          Konsultointi-palvelu: Markkinoinnin ja asiakkuuksien ongelmien
          tunnistaminen
        </p>
        <p className="blurb-text blurb-text-3 cursive right">
          Markkinoinnin kehittämistavoitteet
        </p>
        <p className="blurb-text blurb-text-4 cursive ">
          Markkinointi- ja digistrategia
        </p>
        <p className="blurb-text blurb-text-5 cursive right">
          Markkinoinnin toimenpidesuunnitelma
        </p>
        <p className="blurb-text blurb-text-6 cursive ">
          Toimenpiteiden integrointi myyntiin ja liiketoiminnan arkeen
        </p>
      </SteppedStyles>
    </div>
  );
}
